import { storeToRefs } from 'pinia';
import { useAuthStore } from '../stores/authStore'
import axios from 'axios';
import Cookies from 'js-cookie'

const BASE_API='https://consulting-api-nest.azurewebsites.net/'

const useAuth = () => {
    const authStore = useAuthStore();
    
    const {user, error, loading} = storeToRefs(authStore);


    const login = async ({email, password}) => {
        loading.value = true;
        try {
            const response = await axios.post(BASE_API+'auth/login',
            {
                "email":email,
                "password" : password   
            });
            await getUserFromToken(response?.data?.token);
           
            loading.value = false;

        } catch (err) {
            loading.value = false;
            error.value = "Credenciales Inválidas";
        }
       
        loading.value = false;
    
    };


    const register= async ({email, name, password}) => {
        
        loading.value = true;
        try {
            authStore.loadUser(await axios.post(BASE_API+'auth/register',
            {
                "name":name,
                "email":email,
                "password" : password,
                "role":"USER"
            }))
        loading.value = false;
        } catch (err) {
            error.value = err
            loading.value = false;
        }
        loading.value = false;
    
    };

    const getUserFromToken = async (token)=> {
        loading.value = true;
    
        try {
            authStore.loadUser(await axios.get(BASE_API+'auth/me',
            {
                headers: {"Authorization":`Bearer ${token}`}
                
            }))
            
            Cookies.set('auth_token',token)
            loading.value = false;
             
           } catch (err) {
            error.value = err.response.data.message;           
            
            loading.value = false;
        }
    }

    const logout = () => {
        user.value = {};
        Cookies.remove('auth_token')
    }
    return {
        // Properties
        user,
        error,
        loading,

        //methods
        login,
        register,
        getUserFromToken,
        logout

    }
}
export default useAuth;
