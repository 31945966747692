import { defineStore } from 'pinia';



export const useQueryPostsStore = defineStore({
    id: 'queryposts',
    state: () => ({
        titleQuery:'',
        posts: [] ,
        selectedPost: {},
        prevFilters: {},
        error: '',
        loading: false,
        results: 0,
        page:1,
        nextPage:null,
        prevPage:null,
        totalPages:1,
    }),
    getters: {},
    actions: {
        getPostById(id) {
            this.selectedPost = this.posts?.find((post) => post.id == id);
            
        },
        loadPosts(data) {
            try {
                this.posts = data.data.data;
                this.results = data.data.total;
                this.totalPages = data.data.totalPages;
                this.nextPage = data.data.nextPage;
                this.prevPage = data.data.prevPage;
                
                this.page = data.data.page;
           } catch (error) {
                this.error = error;
                console.error(error);
           }
        },
     
    }
});

